import {Component, OnInit} from '@angular/core';
import {DocumentFile, PageImages} from '@models/document-file';
import {Box} from '@components/main/review/human-validation/models/box';
import {DataViewCell} from '@models/dataview';
import {StatementService} from '@services/statement.service';
import {CommonFunctions} from '@utils/common-functions';
import {AlertService} from '@services/alert.service';
import {AutoUnsubscribe} from 'app/decorators/auto-unsubscribe';
import {Subscription} from 'rxjs/Subscription';
import {UserService} from "@services/user.service";
import {SharedDataService} from "@services/shared-data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HandsontableDataService} from "./handsontable-data.service";
import {finalize} from "rxjs/operators";
import {NavigationStep} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";

const blankCellMetadataObject = {
  text: '',
  raw_text: '',
  translated_raw_text: '',
  sourceBox: null
};

@AutoUnsubscribe('subsArr$')
@Component({
  selector: 'app-table-merging',
  templateUrl: './table-merging.component.html',
  styleUrls: ['./shared-table-merging-header-styles.scss'],
  providers: [HandsontableDataService]
})
export class TableMergingComponent implements OnInit {
  subsArr$: Subscription[] = [];
  isLoading = false;
  currentStepIndex = 0;

  // values defined in url
  insertAtLineItemId = -1 // line item we are inserting after

  // values determined in resolver loadStatement and listDocument api calls
  statementId: number;
  fullSpreadColumns = null;
  fileId: number;
  documentFile: DocumentFile

  // output from table drawing step
  currentTableMergeSelection: { box: Box, pageIndex: number, pageImage: PageImages } = null;

  // determined between table drawing and column adjustment, used for col adjustment inputs and in safe request
  cells: Array<DataViewCell> = null;
  columnStructure: Array<Box> = [];
  tableId: number = null;

  currency = null; // passed to final review component

  roundingSelection: 'thousands' | 'millions' | null;

  navigationSteps: NavigationStep[] = [
    {
      displayText: 'Draw Table',
      stepKey: 'TABLE_DRAWING',
      action: () => this.currentStepIndex = 0,
      helpDescription: "This feature allows you to merge data from footnotes, schedules, or other areas of a document directly into the Data Categorization table. First, select the page containing the data you want to merge. Second, in the selected page, draw a table around the data. You can resize the drawn area by clicking on the corners of the box and dragging in any direction. Click next to continue on to the next step."
      // TODO add collapsable sidebar with help text
    },
    {
      displayText: "Column Adjustment",
      stepKey: 'TABULATION',
      action: () => this.currentStepIndex = 1,
      helpDescription: "Delete any columns that are not needed. After deleting a column, click save to refresh the selected columns. When ready, click next to move on to the final step. Note: The new table must have the same column and period structure as the original statement."
    },
    {
      displayText: "Data Review",
      stepKey: 'FINAL_REVIEW',
      helpDescription: "Review the data and click Merge Into Spread to merge this data into the spread. Note: Header information cannot be changed in this step because they contain the data from the original statement."
    }
  ];


  constructor(
    private _statementService: StatementService,
    private _alertService: AlertService,
    private _userService: UserService,
    private _sharedDataService: SharedDataService,
    private _router: Router,
    private _route: ActivatedRoute,
    public htDataService: HandsontableDataService
  ) {
    const tableMergingPageData = this._route.snapshot.data.tableMergingPageData;
    // parse loadStatementResponse
    this.statementId = tableMergingPageData.statementData.id;
    this.fileId = tableMergingPageData.statementData.documentFileId;
    this.fullSpreadColumns = tableMergingPageData.statementData.columns;
    this.roundingSelection = tableMergingPageData.statementData.defaultRoundingScale
    // parse loadDocument response
    this.documentFile = tableMergingPageData.documentData
  }

  ngOnInit() {
    if (!this._userService.isSpreader()) {
      this._router.navigate(['companies']);
    }
    this._sharedDataService.hideDefaultNavigation();
    this.insertAtLineItemId = parseInt(this._route.snapshot.queryParamMap.get('targetLineItem'));
  }

  completeTableDrawing(): void {
    // validate box selection
    if (!this.currentTableMergeSelection || !this.currentTableMergeSelection.box) {
      this._alertService.error('Please draw a box before proceeding');
      return
    }
    this.isLoading = true;
    this._statementService.startMergeTableProcess(this.fileId, this.currentTableMergeSelection.box, this.currentTableMergeSelection.pageIndex)
      .pipe(finalize(() => {
        this.isLoading = false
      }))
      .subscribe(data => {
          // This transition was previously the 'openModal' action, passing inputs
          this.cells = data.data.cells;
          this.columnStructure = data.data.columnStructure.map(b => new Box().deserialize(b));
          this.tableId = data.id;
          this.currentStepIndex = 1;
        },
        error => {
          this._alertService.error('Unable to start table merging process: an unknown error occurred');
          console.log('error', error);
        });
  }

  updateTableMergeSelection(event) {
    this.currentTableMergeSelection = event;
  }

  updateRoundingSelection(roundingSelection) {
    this.roundingSelection = roundingSelection;
  }

  completeTableAdjustments(): void {
    this.saveAdjustedTables(true);
  }

  saveAdjustedTables(movingToFinalReview = false): void {
    this.isLoading = true;
    this.subsArr$.push(this._statementService.saveMergeTableAdjustments(this.fileId, this.tableId, this.columnStructure, this.currentTableMergeSelection.box, this.currentTableMergeSelection.pageIndex).subscribe(data => {
        this.cells = data.data.cells;
        this.htDataService.cellMetadata = data.data.cells;
        this.columnStructure = data.data.columnStructure.map(b => new Box().deserialize(b));

        if (movingToFinalReview) {
          this.subsArr$.push(this._statementService.getManualReviewData(this.fileId, this.tableId, this.currentTableMergeSelection.box, this.currentTableMergeSelection.pageIndex).subscribe(page => {
              this.currency = page?.currency;

              this.setPageTabularData(page);
              this.currentStepIndex = 2;
              this.isLoading = false;
            },
            error => {
              this.isLoading = false;
              this._alertService.error(error);
            }));
        } else {
          this.isLoading = false;
        }
      },
      error => {
        this.isLoading = false;
        this._alertService.error(error);
      }));
  }

  setPageTabularData(page: any) {
    const grid = []

    if (page.hasOwnProperty('metadata')) {
      const metadataNumPeriods = page.metadata[0].length - 1; // account for labels field
      this.htDataService.metadataNumItems = page.metadata.length;
      if (this.fullSpreadColumns.length > metadataNumPeriods) {
        const numColDifference = this.fullSpreadColumns.length - metadataNumPeriods;
        page = this.increasePageMetadataBy(numColDifference, page);
      }

      page.metadata.forEach((row, rowIndex) => {
        const cleanRow = row.map((string, index) => {
          const spreadColumnIndex = index - 1;
          if (index > 0 && this.fullSpreadColumns[spreadColumnIndex] && this.fullSpreadColumns[spreadColumnIndex] !== null) {
            const expectedKey = CommonFunctions.phraseToCamel(row[0]);

            if (this.fullSpreadColumns[spreadColumnIndex].hasOwnProperty(expectedKey)) {
              return this.fullSpreadColumns[spreadColumnIndex][expectedKey];
            }
          }

          return string;
        });
        grid.push(cleanRow);
      });
    } else {
      console.log('no page.metadata. This should never happen. Here for possible FullStory debugging');
    }

    const expectedRowLength = this.fullSpreadColumns.length + 1
    page.cells.forEach(row => {
      const cleanRow = row.map((cell) => {
        if (cell.translatedRawText) {
          this.htDataService.translationDict[cell.text] = cell.translatedRawText;
        }
        return cell.text;
      });

      // need to seed placeholder data in empty cells to maintain the correct structure.
      if (cleanRow.length < expectedRowLength) {
        this.padRowWithEmptyCells(cleanRow, expectedRowLength - cleanRow.length)
      }
      grid.push(cleanRow);
    });


    this.htDataService.pageData = grid;
    this.htDataService.fullSpreadColumnsLength = this.fullSpreadColumns.length;
  }

  padRowWithEmptyCells(row, count) {
    for (let i=0; i<count; i++){
      row.push(null);
    }
  }

  completeTableMerge(): void {
    if (!this.htDataService.isCorrectNumberOfColumns(this.fullSpreadColumns.length + 1)) {
      this._alertService.error('Please remove any extra columns to match the new table\'s columns structure with the existing statement');
      return;
    }
    if (!this.htDataService.allRowsHaveLineItemLabels()){
      this._alertService.error('Please add a label for each line item or remove empty rows.')
      return
    }
    const oldPage = this.htDataService.getOldPageFormat();
    this.isLoading = true;
    this.subsArr$.push(this._statementService.completeTableMerge(this.statementId, this.insertAtLineItemId, oldPage, this.roundingSelection)
      .pipe(finalize(() => {
        this.isLoading = false
      }))
      .subscribe(data => {
        this._alertService.success("Data successfully merged")
        this.redirectBackToCategorization();
      }, error => {
        if (error.error_type === 'ValidationError') {
          this._alertService.error(error.message);
        } else {
          this._alertService.error(error.message);
        }
      }));

    return;
  }


  increasePageMetadataBy(numItemsToIncrease: number, page: any): any {
    page.metadata.forEach((metaDataItem, index) => {
      for (let i = 0; i < numItemsToIncrease; i++) {
        page.metadata[index].push('');
      }
    });

    const cellData = this.htDataService.cellMetadata;
    cellData.forEach((cell, index) => {
      for (let i = 0; i < numItemsToIncrease; i++) {
        cellData[index].push(blankCellMetadataObject);
      }
    })

    this.htDataService.cellMetadata = cellData;

    return page;
  }

  redirectBackToCategorization() {
    this._router.navigate(['../edit'], {relativeTo: this._route})
  }
}
