<ng-container>
    <ng-container *ngIf="navigationSteps.length" [ngSwitch]="this.navigationSteps[currentStepIndex].stepKey">
        <app-table-drawing
            *ngSwitchCase="'TABLE_DRAWING'"
            [documentFile]="documentFile"
            [navigationSteps]="navigationSteps"
            (exitClicked)="redirectBackToCategorization()"
            (nextClicked)="completeTableDrawing()"
            (tableMergeSelection)="updateTableMergeSelection($event)"
        ></app-table-drawing>

        <!-- this is the non-editable table preview, like what we have in normal column adjustement step on right -->
        <app-table-tabulation
            *ngSwitchCase="'TABULATION'"
            [cells]="cells"
            [columnStructure]="columnStructure"
            [pageImage]="currentTableMergeSelection.pageImage"
            [navigationSteps]="navigationSteps"
            (exitClicked)="redirectBackToCategorization()"
            (nextClicked)="completeTableAdjustments()"
            (saveClicked)="saveAdjustedTables()"
        ></app-table-tabulation>

        <!-- this is the manual review table -->
        <app-final-review
            *ngSwitchCase="'FINAL_REVIEW'"
            [translationDict]="htDataService.translationDict"
            [currency]="currency"
            [navigationSteps]="navigationSteps"
            [columnStructure]="columnStructure"
            [pageImage]="currentTableMergeSelection.pageImage"
            (exitClicked)="redirectBackToCategorization()"
            (nextClicked)="completeTableMerge()"
            (updateRoundingSelection)="updateRoundingSelection($event)"
            [roundingSelection]="roundingSelection"
        ></app-final-review>
    </ng-container>

</ng-container>

<ng-container *ngIf="isLoading">
    <div class="loader__overlay">
        <div class="loader"></div>
    </div>
</ng-container>
