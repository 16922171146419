import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { NgxPopupComponent } from '../../../../shared/ngx-popups/ngx-popups/components/popup.component';
import { NgxPopupService } from '../../../../shared/ngx-popups/ngx-popups/services/ngx-popup.service';
import { AnalysisFormComponent } from '../../../../shared/analysis-form/analysis-form.component';
import { Company } from '../../../../../../app/models/company';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SharedDataService } from '../../../../../services/shared-data.service';
import { AnalysisService } from '../../../../../services/analysis.service';
import { AutoUnsubscribe } from '../../../../../decorators/auto-unsubscribe';
import { LaunchDarklyService } from '../../../../../services/launchdarkly.service';
import { Analysis } from '../../../../../models/analysis';
import { ConfirmationPopupComponent } from '@components/shared/popups/confirmation/confirmation-popup.component';
import { AlertService } from '@services/alert.service';
import { UserGuideService } from '@services/user-guide.service';
import {USER_GUIDES, COMPANY_PAGE_VIEW_MODE, COMPANY_TABS, COMPANY_ENTITLEMENT_DATA} from '@utils/constants';
import { GlobalCashflowAnalysisFormComponent } from '@components/shared/global-cashflow-analysis-form/global-cashflow-analysis-form.component';
import { GlobalCashflowService } from '@services/global-cashflow.service';
import {UserService} from "@services/user.service";


@Component({
  selector: 'app-list-analyses',
  templateUrl: './list-analyses.component.html',
  styleUrls: ['./list-analyses.component.scss']
})
@AutoUnsubscribe('subsArr$')
export class ListAnalysesComponent implements OnInit, OnDestroy {
  @Input() currency = 'USD';

  subsArr$: Subscription[] = [];
  company: Company = null;
  analyses = [];
  globalCashflowAnalyses = [];
  globalCashflowAnalysesLoading = true;
  analysesLoading = true;
  isGlobalCashflowShown = false;
  globalCoachFlowAnalyses = [];
  openedAnalysisMenuIndex = -1;
  openedGlobalCashflowMenuIndex = -1;

  // View mode
  pageViewMode = COMPANY_PAGE_VIEW_MODE.NO_ACCESS
  noAccessErrorText = "You don't have enough permissions to view this page."
  canViewAnalyses: boolean;
  routeData: Data;

  // empty state
  noAnalysesHelpText = 'No analyses have been created for this borrower.';
  noGcfAnalysesHelpText = 'No global cashflow analyses have been created for this borrower.';


  constructor(
    private _popupService: NgxPopupService,
    private _sharedData: SharedDataService,
    private _analysisService: AnalysisService,
    private _featureFlags: LaunchDarklyService,
    private _alertService: AlertService,
    private globalCashflowService: GlobalCashflowService,
    public userGuideService: UserGuideService,
    public _userService: UserService,
    private _route: ActivatedRoute,
  ) {
    this.routeData = this._route.snapshot.parent.data;
  }

  ngOnInit() {
    this.pageViewMode = this.routeData.companyEntitlement[COMPANY_TABS.ANALYSES][COMPANY_ENTITLEMENT_DATA.PAGE_VIEW_MODE];
    if ([COMPANY_PAGE_VIEW_MODE.FULL, COMPANY_PAGE_VIEW_MODE.VIEW_ONLY].includes(this.pageViewMode)) {
      this.canViewAnalyses = [COMPANY_PAGE_VIEW_MODE.VIEW_ONLY, COMPANY_PAGE_VIEW_MODE.FULL].includes(this.pageViewMode)
      this.subsArr$.push(this._sharedData.company$.pipe(
        first(company => company !== null))
        .subscribe((company: Company) => {
          this.company = company;
          this.subsArr$.push(this._analysisService.getAnalyses(this.company.id)
          .subscribe(data => {
            this.analyses = data;
            this.analysesLoading = false;
          }));
  
          this.subsArr$.push(this.globalCashflowService.getGlobalCashflowAnalyses(this.company.id)
          .subscribe(data => {
            this.globalCashflowAnalyses = data;
            this.globalCashflowAnalysesLoading = false;
          }));
  
        }),
      );
  
  
      this.updateFlags(this._featureFlags.flags);
      this.subsArr$.push(this._featureFlags.flagChange.subscribe(flags => {
        this.updateFlags(flags);
      }));
  
      this.userGuideService.add(USER_GUIDES.CREATING_ANALYSES);
    }
  }

  ngOnDestroy() {
    this.userGuideService.remove(USER_GUIDES.CREATING_ANALYSES);
  }

  displayCreateAndDeleteAnalysisButtons() {
    return this.pageViewMode === COMPANY_PAGE_VIEW_MODE.FULL;
  }

  addAnalysis() {
    const company = this.company;
    this._popupService.open({
      componentType: AnalysisFormComponent,
      cssClass: 'modal-add-client',
      inputs: {
        company,
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        // wha tto do on close? Anything?
        // possible redirect you to the new analysis page.
      }, {once: true});
    });
  }

  addGlobalCashflow() {
    const company = this.company;
    this._popupService.open({
      componentType: GlobalCashflowAnalysisFormComponent,
      cssClass: 'modal-add-client',
      inputs: {
        company,
        formType: 'ADD',
      },
    }).then((popup: NgxPopupComponent) => {
      popup.addEventListener('close', (data: CustomEvent) => {
        // wha tto do on close? Anything?
        // possible redirect you to the new analysis page.
      }, {once: true});
    });
  }

  totalProposedDebt(analysis: Analysis): number {
    return this._analysisService.totalProposedDebt(analysis);
  }

  numProposedDebts(analysis: Analysis): number {
    return this._analysisService.numProposedDebts(analysis);
  }

  openAnalysisMenu(index: number): void {
    if (index === this.openedAnalysisMenuIndex) {
      this.openedAnalysisMenuIndex = -1;
      return;
    }

    this.openedAnalysisMenuIndex = index;
  }

  openGlobalCashflowMenu(index: number): void {
    if (index === this.openedGlobalCashflowMenuIndex) {
      this.openedGlobalCashflowMenuIndex = -1;
      return;
    }

    this.openedGlobalCashflowMenuIndex = index;
  }

  deleteAnalysis(analysis: Analysis, index: number, evt: any) {
    evt.preventDefault();

    // Close the analysis action menu
    this.openAnalysisMenu(index)
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure you want to delete this analysis?',
        text: analysis.name,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.subsArr$.push(this._analysisService.deleteAnalysis(analysis.id).subscribe(() => {
              this._alertService.success('Analysis deleted successfully');
              this.subsArr$.push(this._analysisService.getAnalyses(this.company.id)
                .subscribe(data => {
                  this.analyses = data;
                }));

            }, (err) => {
              this._alertService.error('Oops! Failed to delete analysis');
            }));
          }
        }
      },
    });
  }


  deleteGlobalCashflowAnalysis(gcfAnalysis: Analysis, analysisIndex: number, evt: any) {
    evt.preventDefault();

    // Calling this will close the menu that was initially opened to list the options
    this.openGlobalCashflowMenu(analysisIndex)
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Are you sure you want to delete this analysis?',
        text: gcfAnalysis.name,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.subsArr$.push(this.globalCashflowService.deleteGlobalCashflowAnalysis(gcfAnalysis.id).subscribe(() => {
              this._alertService.success('Global cashflow analysis deleted successfully');
              this.subsArr$.push(this.globalCashflowService.getGlobalCashflowAnalyses(this.company.id)
              .subscribe(data => {
                this.globalCashflowAnalyses = data;
              }));
            }, (err) => {
              this._alertService.error('Failed to delete gloabl cashflow analysis');
            }));
          }
        }
      },
    });
  }

  private updateFlags(flags: object) {
    this.isGlobalCashflowShown = flags['global-cashflow'];
  }
}
