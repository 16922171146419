import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cell, Row } from '@components/main/spreading/models/models';
import { SourceComment } from '@components/main/spreading/models/source-comment';
import { SpreadingState } from '@components/main/spreading/state/manager';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent {
  @Input() popup: NgxPopupComponent;

  @Input() cellWithComment: Cell;
  @Input() rowWithCell: Row;

  @Input() spreadingState: SpreadingState;

  @Output() saveComment: EventEmitter<any> = new EventEmitter<any>();

  closeModal() {
    this.popup.close();
  }
  constructor(
  ) {
  }

  saveSpreadingComment(commentText): void {
    if (this.cellWithComment.sourceComments.length === 0) {
      this.cellWithComment.sourceComments[0] = {
        id: null,
        uuid: null,
        comment: '',
        reviewQueueItemId: null,
        sourcePageNumber: null,
        sourceBoxUlcX: null,
        sourceBoxUlcY: null,
        sourceBoxUrcX: null,
        sourceBoxUrcY: null,
        sourceBoxLrcX: null,
        sourceBoxLrcY: null,
        sourceBoxLlcX: null,
        sourceBoxLlcY: null,
        statementId: null
      }
    }
    this.cellWithComment.sourceComments[0].reviewQueueItemId = this.spreadingState.reviewQueueItem.id;
    this.populateSourceComment(this.cellWithComment.sourceComments[0], this.spreadingState, commentText.value);
    this.saveComment.emit();
    this.closeModal();
    return;
  }

  private populateSourceComment(sourceComment: SourceComment, spreadingState: SpreadingState, commentText: string): void {
    sourceComment.comment = commentText;
    sourceComment.statementId = spreadingState.statement.id;
  }

  protected readonly document = document;
}
