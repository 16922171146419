import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-comment-container',
  templateUrl: './comment-container.component.html',
  styleUrls: ['./comment-container.component.scss']
})
export class CommentContainerComponent {

  @Input() commentText: string = '';
  @Output() editContent = new EventEmitter<any>();
  @Output() deleteContent = new EventEmitter<any>();
  constructor() {
  }

  edit() {
    this.editContent.emit();
  }

  delete() {
    this.deleteContent.emit();
  }
}
