<div class="modal__content">
  <div class="modal-header">
      <i class="fa-solid fa-trash header-icon"></i> DELETE COMMENT
  </div>
  <div class="modal-text">
      Are you sure you want to delete this comment?
  </div>
</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="closeModal();">Cancel</button>
    <button class="btn btn-danger" (click)="deleteComment(cellWithComment)">Delete</button>
</div>
