import { Injectable } from '@angular/core';
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { SignedUrl } from '../models/signed-url';
import { SignedUrlService } from './signed-url.service';
import { Router } from '@angular/router';
import { CommonFunctions } from '../utils/common-functions';
import { LaunchDarklyService } from './launchdarkly.service';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';
import { AppConfigService } from './app-config.service';
import { EnvironmentService } from '@services/environment.service';
import { ApiService } from '@services/api.service';
import { User } from '@models/user';
import {SESSION_IDLE_STATES, SESSION_IDLE_STATUS_KEY} from "@services/user-session-timeout.service";

const RECENT_COGNITO_SESSIONS_KEY = 'recent_cognito_sessions'
const HAD_IDENTITY_SERVICE_SESSION_KEY = 'had_identity_service_session'

@Injectable()
@AutoUnsubscribe('subsArr$')
export class AuthenticationService {

  subsArr$: Subscription[] = [];

  currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
      private _userService: UserService,
      private _jwtService: JwtHelperService,
      private _signedUrlService: SignedUrlService,
      private _appConfigService: AppConfigService,
      private _router: Router,
      private ld: LaunchDarklyService,
      private _environmentService: EnvironmentService,
      private _apiService: ApiService,
  ) { }

  /**
   * handles logout of the user
   */
  logout() {
    const recent_cognito_sessions = JSON.parse(localStorage.getItem(RECENT_COGNITO_SESSIONS_KEY))

    if (recent_cognito_sessions) {
      // We have already asked the backend about any sessions we have
      // with Identity Service, so we can proceed with logging out.
      // (Note that this branch triggers even if recent_cognito_sessions = [].)
      this._clear_sessions_then_log_out(recent_cognito_sessions)
    } else {
      // No localStorage entry was set for RECENT_COGNITO_SESSIONS_KEY.
      // (recent_cognito_sessions was null).  Therefore this is our first
      // invocation of logout() and we should check if we have any
      // Identity Service sessions to clear out.
      this._apiService.send('get', `/api/identity/sessions`).subscribe(data => {
        this._clear_sessions_then_log_out(data.recent_cognito_sessions)
      }, error => {
        // If the API errors, there's nothing we can do.  Proceed as though we
        // got an empty response.  Better to log out insofar as we can and land
        // somewhere, as opposed to breaking the page and leaving sessions open.
        this._clear_sessions_then_log_out([])
      })
    }
  }

  _clear_sessions_then_log_out(data) {
    // Clear Cognito sessions sequentially.
    //
    // Doing it in parallel would be better (especially if a region went down),
    // but that requires pop-up windows which tend to be blocked.
    if (data.length > 0) {
      const session = data.pop()
      localStorage.setItem(HAD_IDENTITY_SERVICE_SESSION_KEY, '1')
      localStorage.setItem(RECENT_COGNITO_SESSIONS_KEY, JSON.stringify(data))
      window.open(session.url, '_self')
      return
    }

    // Now that all Cognito sessions are cleared, we can proceed with the
    // rest of the normal logout flow (just changing our final destiation
    // based on whether we're an Identity Service user or not).
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('bank_id');
    localStorage.removeItem('hasAccessToken');
    localStorage.removeItem('hasAccessToken');
    localStorage.setItem(SESSION_IDLE_STATUS_KEY, SESSION_IDLE_STATES.LOGOUT);
    this._userService.user = null;

    // this.ld.setAnonymous(); // Now also log them out of cognito // commented out as causing a sentry error

    localStorage.removeItem(RECENT_COGNITO_SESSIONS_KEY)
    if (localStorage.getItem(HAD_IDENTITY_SERVICE_SESSION_KEY)) {
      localStorage.removeItem(HAD_IDENTITY_SERVICE_SESSION_KEY)
      this._complete_logout_for_identity_service()
    } else {
      this._complete_logout_for_spreading_pool()
    }
  }

  _complete_logout_for_identity_service() {
    // If we have a specific logout destination based on how we signed in,
    // take the user there.  Otherwise, end on a generic/static page.
    if (localStorage.getItem(`sso_logout_destination`)) {
      const sso_logout_destination = localStorage.getItem(`sso_logout_destination`)
      localStorage.removeItem(`sso_logout_destination`)
      window.location.href = sso_logout_destination
    } else {
      this._appConfigService.envFromHostname.subscribe((envFromHost) => {
        const redirectUrl = envFromHost.serverUrl + '/api/logout?client_id=identity-service';
        this.redirectTo(redirectUrl);
      });
    }
  }

  _complete_logout_for_spreading_pool() {
    this._appConfigService.envFromHostname.subscribe((envFromHost) => {
      const redirectUrl = envFromHost.serverUrl + '/api/logout?client_id=fincura-app';
      this.redirectTo(redirectUrl);
    });
  }

  redirectTo(url: string) {
    window.location.href = url;
  }

  isAuthenticatedWithSignedUrl(): Observable<boolean> {
    const signedUrl = new SignedUrl();

    signedUrl.jwt = CommonFunctions.getQueryParameterByName('jwt');

    if (!signedUrl.jwt) {
      return of(false);
    }

    try {
      signedUrl.jwtPayload = this._jwtService.decodeToken(signedUrl.jwt);
    } catch (ex) {
      this._router.navigate(['/denied']);
      return of(false);
    }

    signedUrl.key = signedUrl.jwtPayload['suk'];

    return this._signedUrlService.validateSignedUrl(signedUrl).pipe(
      map((resp) => {
        if (resp) {
          return true;
        }
      }),
      catchError((err) => {
        this._router.navigate(['/denied']);
        return of(false);
      })
    );
  }

  exchangeKeyForJWT(key, bankId): Observable<any> {
    const signedUrl = new SignedUrl();
    signedUrl.key = key;

    return this._signedUrlService.getJWTFromKey(signedUrl, bankId).pipe(
      map((resp) => {
        if (resp) {
          return resp.jwt
        }
      }),
      catchError((err) => {
        this._router.navigate(['/denied']);
        return err;
      })
    );
  }
}
