<div>
  <div class="modal_header">
      <i class="fa-solid fa-comment fa-flip-horizontal header-icon"></i> COMMENT
  </div>
  <div class="modal__content">
      <div class="comment-subheading">
          This comment is on a cell for the line item:
      </div>
      <div class="comment-row-label">
          {{ rowWithCell?.label }}
      </div>
      <div>
          <label for="userInput" class="comment-input-label"></label>
          <textarea class="comment-input-box" id="userInput" name="userInput" value="{{ cellWithComment?.sourceComments[0]?.comment }}"></textarea>
      </div>
  </div>
</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="closeModal();">Cancel</button>
    <button class="btn btn-primary" (click)="saveSpreadingComment(document.getElementById('userInput'))">Save</button>
</div>
