<div class="top-header">
    <div class="title-action-container">
        <div class="title-container">
            <div class="company-name">{{companyName}}</div>
            <div class="separator"> |</div>
            <div *ngIf="document && !(document?.embeddedWorkflow); else staticDocName" class="document-name">
                <a target="_blank" [routerLink]="['/review', document.id, 'fullscreen']">{{documentName}}<i
                    class="fas fa-arrow-up-right-from-square"></i></a>
            </div>
            <ng-template #staticDocName>
                <div class="static-document-name">{{documentName}}</div>
            </ng-template>
        </div>
        <div class="action-container">

            <div class="doc-status-btns">
                <i *ngIf="currentStep === 'Spreading'"
                   class="fas fa-keyboard toolbar"
                   (click)="openShortcutsModal()"
                   title="Keyboard Shortcuts">
                </i>

                <ng-container *ngIf="!embedded && showDocumentStatusButton">
                    <a
                        class="clickable"
                        href="javascript:void(0);"
                        [satPopoverAnchor]="uploadStatusPopover"
                        (click)="uploadStatusPopover.toggle()"
                    >
                        <div class="avatar">
                            <i class="far fa-check-square"></i>
                        </div>
                    </a>

                    <sat-popover #uploadStatusPopover hasBackdrop verticalAlign="below">
                        <app-upload-status-popover
                            (closePopover)="uploadStatusPopover.toggle()"
                            [isManualReviewer]="shouldDisplayManualReview()"
                            [isSpreader]="shouldDisplaySpreading()"
                        >
                        </app-upload-status-popover>
                    </sat-popover>
                </ng-container>
                <ng-container *ngIf="!embedded">
                    <div class="dropdown">
                        <a href="#" id="nbAcctDD" class="dropdown-toggle-no-caret" data-bs-toggle="dropdown">
                            <div class="avatar">
                                <i class="far fa-question-circle"> </i>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <ng-container *ngIf="showNumeratedSalesforceHelpLinks">
                                <li class="walkthrough-header" *ngIf="userGuideService.userGuides.length > 0"><strong>Articles</strong>
                                </li>
                                <li *ngFor="let userGuide of userGuideService.userGuides">
                                    <a target="_blank" href="{{userGuide.url}}" class="dropdown-item">{{userGuide.name}}</a>
                                </li>
                                <li *ngIf="userGuideService.userGuides.length > 0"
                                    role="separator" class="dropdown-divider"></li>
                                <li class="user-guide">
                                    <div *ngIf = "shouldShowInstructionsInDropdown" class="dropdown-item no-hover-background" (click)="onTextClick($event)">
                                            <input [checked]="_sharedDataService.shouldShowManualReviewSidebar" type = "checkbox" class = "fas fa-checkbox">
                                            <span class = "instruction-text">Show Instructions</span>
                                    </div>
                                </li>
                                <li class="user-guide">
                                    <a target="_blank" href="https://numeratedcrm.my.site.com/support/s/" class="dropdown-item">
                                        <i class="fas fa-clipboard-user"></i>User Guide
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="showStorylaneTrainingButton">
                                <li class="how-to-use-numerated">
                                    <a target="_blank" href="https://numerated.storylane.io/share/mbgtcrj0yghv" class="dropdown-item">
                                        <i class="fas fa-book"></i>How to Use Numerated
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="contactSupportEmail">
                                <li role="separator" class="dropdown-divider"></li>
                                <li class="contact-support-option">
                                    <a class="dropdown-item"
                                       (click)="openContactSupportEmailModal()">
                                        <i class="fas fa-question-circle"></i>Contact Support
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>
            <button
                *ngIf="!embedded"
                class="btn btn-outline-secondary header-action-btn"
                type="button"
                (click)="navigate('back')"
            >
                <span class="size">Exit</span>
            </button>

            <div *ngIf="!embedded" class="btn-separator">|</div>

            <button class="btn btn-light-primary" type="button" (click)="save()">
                <span class="size">Save</span>
            </button>

            <button
                class="btn btn-primary"
                type="button"
                (click)="next()"
            >
                <span *ngIf="currentStep !== 'Spreading'" class="size">Next</span>
                <span *ngIf="currentStep === 'Spreading'" class="size">Complete</span>
            </button>
        </div>

    </div>
    <div class="stepper-and-page-action-container">
        <app-navigation-stepper
            [steps]="navigationSteps"
            [currentStepIndex]="currentStepIndex"
        ></app-navigation-stepper>
        <div class="page-action-container">
            <ng-content></ng-content>
        </div>
    </div>
</div>
