import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  getNonHeaderCellValidationFailure,
  isValidValueCell,
  isWarningCell,
  notNumberWithDecimalCommaDashRegex
} from "../../../review/review-editor/validation";
import {
  DISABLED_HEADER_VALUE_STYLE,
  OPTION_LABEL_CELL_STYLE,
  PROBLEM_CELL_STYLE,
  WARNING_CELL_STYLE
} from "@utils/constants";
import {CurrencyFormattingService} from "@services/currency-formatting.service";
import {AutoUnsubscribe} from "@decorators/auto-unsubscribe";
import {Subscription} from "rxjs/Subscription";
import {HandsontableDataService} from "@components/main/spreading/table-merging/handsontable-data.service";
import {Box} from "@components/main/review/human-validation/models/box";
import {PageImages} from "@models/document-file";
import {NavigationStep} from "@components/shared/digitization-top-nav/navigation-stepper/navigation-stepper.component";


@Component({
  selector: 'app-final-review',
  templateUrl: './final-review.component.html',
  styleUrls: ['./final-review.component.scss', '../shared-table-merging-header-styles.scss']
})
@AutoUnsubscribe('subsArr$')
export class FinalReviewComponent {
  @Input() translationDict: any
  @Input() currency: any
  @Input() columnStructure: Array<Box> = [];
  @Input() pageImage: PageImages;
  @Input() navigationSteps: NavigationStep[];
  @Input() roundingSelection: string;
  @Output() exitClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();
  @Output() updateRoundingSelection = new EventEmitter<string>();

  subsArr$: Subscription[] = [];
  renderCell = this._renderCell.bind(this);

  constructor(
    private _currencyFormattingService: CurrencyFormattingService,
    public htDataService: HandsontableDataService,
  ) {}


  cellsFunction = (row, col, prop) => {
    const cellProperties: { readOnly: boolean } = {readOnly: false};

    if (row < this.htDataService.metadataNumItems) {
      cellProperties.readOnly = true;
    }
    return cellProperties;
  }

  /**
   * Function passed to hands on table to render the TD for each individual
   * cell. We use this to apply styling rules based on validation.
   */
  _renderCell(instance, td, row, col, prop, cell: string, cellProperties) {
    if (col === 0) {
      td.className = td.className + ' fs-exclude fs-block';
    }
    // Handle case when new row is added
    if (cell === null) {
      cell = '';
    }

    if (isValidValueCell(cell) && cell !== '' && cell.search(notNumberWithDecimalCommaDashRegex) === -1) {
      td.innerHTML = this._formatNumber(cell);
      td.align = 'right';
    } else {
      td.innerHTML = cell;
      td.align = 'left';
    }

    const validationFailure = getNonHeaderCellValidationFailure(row, col, cell, this._currencyFormattingService.getDecimalSeparator(this.currency));

    td.__validationFailure = validationFailure;

    if (row < this.htDataService.metadataNumItems) {
      if (col === 0) {
        Object.assign(td.style, OPTION_LABEL_CELL_STYLE);
      } else {
        Object.assign(td.style, DISABLED_HEADER_VALUE_STYLE);
      }
    } else if (!!validationFailure) {
      if (isWarningCell(cell, row, col)) {
        Object.assign(td.style, WARNING_CELL_STYLE); // if warning, color yellow
      } else {
        Object.assign(td.style, PROBLEM_CELL_STYLE); // if error, color red
      }


    }
    return td;
  }

  /**
   * Function that formats the value of a cell based on the given currency type
   *
   * Formats:
   * USD , CAD, EUR, AUD, HKD, MXN, GBP, SGD: 100,000.00
   * JPY, KRW: 100,000
   * INR: 1,00,00.00
   * RUB: 100.00,00
   * CHF: 100'000.00
   *
   * @param str
   */
  _formatNumber(str: string) {
    return this._currencyFormattingService.formatCurrency(str, this.currency);
  }

  roundingSelectionChanged(e) {
    this.updateRoundingSelection.emit(e.target.value)
  }
}
