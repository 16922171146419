import { DocumentFile } from '../../../../models/document-file';
import { SpreadingMapTemplates } from '../../../../models/standard-item-templates';
import { Statement } from '../../review/human-validation/models/statement';
import {LoadTaxonomyResponse, TemplateItem} from "@models/template-item";

export class StatementDataCache {
  documentFilesById: Map<number, DocumentFile> = null;
  reviewQueueItemIDsByFileId: Map<number, string> = null;
  spreadingTaxonomyBySpreadingTemplateId: Map<number, LoadTaxonomyResponse> = null;
  relatedStatementsByFileId: Map<number, Array<Statement>> = null;

  constructor() {
    this.documentFilesById = new Map<number, DocumentFile>();
    this.reviewQueueItemIDsByFileId = new Map<number, string>();
    this.spreadingTaxonomyBySpreadingTemplateId = new Map<number, LoadTaxonomyResponse>();
    this.relatedStatementsByFileId = new Map<number, Array<Statement>>();
  }
}
