<div class="row title-bar-container">
    <div class="title-bar">
        <div class="template-name">
            <div *ngIf="!isTemplateNameBeingEdited" class="template-name-no-edit-mode">
                <strong>Template Name: </strong>
                {{ spreadingTemplate.name }}
                <i *ngIf="!editTemplateMappingsActivated" class="fas fa-pencil-alt edit-name-icon" (click)="toggleTemplateNameEditMode()"></i>
            </div>
            <div *ngIf="isTemplateNameBeingEdited">
                <strong>Template Name: </strong>
                <input [value]="spreadingTemplate.name" (input)="spreadingTemplate.name = $event.target.value" placeholder="Template Name"/>
                <a class="edit-name-icon" (click)="toggleTemplateNameEditMode()">Save Template Name</a>
            </div>
        </div>
        <div class="edit-spreading-template-buttons">
            <div *ngIf="!editTemplateMappingsActivated">
                <label class="default-template-checkbox-label">Default Template</label>
                <input type="checkbox" class="default-template-checkbox" [(ngModel)]="spreadingTemplate.isDefault"/>
            </div>
            <a *ngIf="!editTemplateMappingsActivated"  class="import-export-button" (click)="beginImportExport()">Import/Export</a>
            <a *ngIf="!editTemplateMappingsActivated && templateId !== null && showDownloadWorkbookButton"
               (click)="openExcelExportReferenceExport()" class="import-export-button">Excel Export Reference Upload</a>
            <ng-container *ngIf="templateType === 'STANDARD' && templateId !== null">
                <a class="edit-mappings-button" (click)="toggleEditTemplateMappings()">{{ !editTemplateMappingsActivated ? 'Edit Template Mappings' : 'Back to Working Template'}}</a>
            </ng-container>
        </div>
    </div>
</div>

<div [hidden]="editTemplateMappingsActivated" class="container-fluid edit-template-body-container">
    <div class="row edit-template-header">
        <div class="col-6 edit-template-header-label">
            <h3>Edit Template</h3>
        </div>
        <div class="col-6 save-template-button-container">
            <button *ngIf="mode == 'create'" (click)="createTemplate()" class="btn btn-primary btn-rounded save-template-button">Create Template</button>
            <button *ngIf="mode == 'update'" (click)="updateTemplate()" class="btn btn-rounded save-template-button btn-primary">
            Save Template</button>
        </div>
    </div>
    <div class="row main-editor-container">
        <div class="col-md-4 fincura-tree-container">
            <h6 class="light-heading">FINCURA TAXONOMY
                <div class="dropdown advanced-dropdown">
                    <a data-bs-toggle="dropdown"><i class="fa fa-angle-down"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li class="dropdown-item"><a (click)="downloadFncCsv()">Download as CSV</a></li>
                    </ul>
                </div>
            </h6>
            <div class="search-wrapper">
                <input class="taxonomy-search-input" #treeFilter (keyup)="taxonomyTree.treeModel.filterNodes(treeFilter.value)" placeholder="&#f002; Search"/>
            </div>
            <div class="tree-container">
                <tree-root
                  #taxonomyTree
                  [nodes]="taxonomyNodes"
                  [options]="taxonomyTreeOptions"
                  >
                  <ng-template #treeNodeTemplate let-node let-index="index">
                    <span
                      [title]="node.data.entityType === 'Individual' ? 'Individual Taxonomy Item' : ''"
                    >
                      {{ node.data.label }}<i *ngIf="node.data.entityType === 'Individual'" class="fa-solid fa-user"></i>
                    </span>
                  </ng-template>
                </tree-root>
            </div>
        </div>
        <div class="col-md-8">
            <div class="working-tree-container">
                <div class="row">
                    <div class="col-sm-12">
                        <h6 class="light-heading">WORKING TEMPLATE
                            <div class="dropdown advanced-dropdown">
                                <a data-bs-toggle="dropdown"><i class="fa fa-angle-down working-tree-option-dropdown"></i></a>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <li class="dropdown-item"><a (click)="addGenericEquation()">Add Custom Calculation</a></li>
                                    <li class="dropdown-item" *ngIf="templateType === 'STANDARD'"><a (click)="setCommonSizeDenominatorOverrides()">Set Common Size Denominators</a></li>
                                    <li class="dropdown-item" *ngIf="templateType === 'DSCR'"><a (click)="addTemplateSection()">Add Section</a></li>
                                    <li class="dropdown-item" *ngIf="templateType === 'STANDARD'"><a (click)="addDASSection()">Add DAS Section</a></li>
                                </ul>
                            </div>
                        </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 working-tree-tile">
                        <div class="tree-container">
                            <tree-root
                                #workingTree
                                [nodes]="workingNodes"
                                [options]="workingTreeOptions"
                                (focus)="onWorkingTreeNodeFocus($event)"
                                >
                                <ng-template #treeNodeTemplate let-node let-index="index">
                                    <span
                                      [ngClass]="{'bold': node.data.displayBold, 'italicized': node.data.className == 'RollupItem', 'item-required': node.data.isRequired }"
                                      [title]="node.data.entityType === 'Individual' ? 'Individual Taxonomy Item' : ''"
                                    >
                                      {{ node.data.label }}<i *ngIf="node.data.entityType === 'Individual'" class="fa-solid fa-user"></i><i *ngIf="node.data.addDefaultLineItemInCategorization" class="fas fa-swatchbook"></i>
                                    </span>
                                </ng-template>
                            </tree-root>
                        </div>
                    </div>
                    <div class="col-sm-6 edit-template-item-container">
                        <h6 class="edit-template-item-header light-heading">Edit Template Item</h6>
                        <ng-container *ngIf="editNode; else noTemplateItemSelected">
                            <div class="cham-panel">
                                <div class="cham-panel__content">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Unique Template Item Label ({{editNode.data.className}})</p>
                                            <input class="node-name-input" #treeFilter (keyup)="editNode.data.label = treeFilter.value;" [value]="editNode.data.label" placeholder="Item Name" [disabled]="editNode.data.className=='GenericCalculatedItem' || editNode.data.className=='CrossSectionDASRefCustomCalc'"/>
                                        </div>
                                    </div>
                                    <div *ngIf="editNode.data.className=='CalculatedItem' || editNode.data.className=='GenericCalculatedItem' || editNode.data.className=='CrossSectionDASRefCustomCalc'" class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">User facing label</p>
                                            <input class="node-name-input" [(ngModel)]="editNode.data.userFacingLabel" placeholder="Label"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Reference ID</p>
                                            <ng-container *ngIf="!editRefMode" >
                                                {{editNode.data.ref}} <span (click)="editRefMode = !editRefMode"><i class="fas fa-edit"></i></span>
                                            </ng-container>
                                            <ng-container *ngIf="editRefMode">
                                                <input [(ngModel)]="editNode.data.ref" type="text" />
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="edit-template-item-option-header">Rollup Behavior</p>
                                            <ng-container>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior=='+'}" (click)="editNode.data.rollupBehavior = '+';" title="Rollup Additive"><i class="fas fa-plus-circle"></i><span>Add</span></button>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior=='-'}" (click)="editNode.data.rollupBehavior = '-';" title="Rollup Subtractive"><i class="fas fa-minus-circle"></i><span>Subtract</span></button>
                                                <button class="btn roll-option-inactive" [ngClass]="{'rollup-option-active': editNode.data.rollupBehavior==''}" (click)="editNode.data.rollupBehavior = '';" title="Rollup Exclude"><i class="fas fa-ban"></i><span>Omit</span></button>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editNode.data.className=='GenericStandardItem' && editNode.data.children.length == 0 && this.templateType === 'STANDARD'">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="edit-template-item-option-header">Create Default Line Item in Categorization</p>
                                                <input
                                                    [checked]="editNode.data.addDefaultLineItemInCategorization"
                                                    (change)="editNode.data.addDefaultLineItemInCategorization = !editNode.data.addDefaultLineItemInCategorization"
                                                    type="checkbox" /> Include for creation
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Display Options</h6>
                                            <input
                                            [checked]="editNode.data.displayBold"
                                            (change)="toggleBold()"
                                            type="checkbox" />
                                            Bold
                                        </div>
                                        <div *ngIf="editNode.data.className != 'RollupItem'" class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Number of Decimals</h6>
                                            <input [(ngModel)]="editNode.data.numDecimals" type="number" />
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="(editNode.data.className=='StandardItem' && editNode.data.children && editNode.data.children.length > 0) || editNode.data.className=='GenericStandardItem'">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Include Rollup Total</h6>
                                            <input
                                            [checked]="editNode.data.hasRollup"
                                            (change)="editNode.data.hasRollup = !editNode.data.hasRollup;"
                                            type="checkbox" />
                                            Show {{editNode.data.rollupLabel}}
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="editNode.data.dynamicAdjustmentStatementItemType=='asReported' || editNode.data.className=='AdjustedStandardItem' || editNode.data.className=='NonAdjustableStandardItem'">
                                        <div class="col-sm-12 pt-3">
                                            <input
                                            [checked]="editNode.data.invertValue"
                                            (change)="editNode.data.invertValue = !editNode.data.invertValue"
                                            type="checkbox" />
                                            Invert Reported Value
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editNode.data.className=='CalculatedItem'">
                                      <div class="row">
                                          <div class="col-sm-12">
                                              <h6 class="edit-template-item-option-header">Default Formula</h6>
                                              <div class="original-equation-container">
                                                <p>{{editNode.data.originalEquation}}</p>
                                              </div>

                                          </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngIf="editNode.data.className=='CalculatedItem' || editNode.data.className=='GenericCalculatedItem' || editNode.data.className=='CrossSectionDASRefCustomCalc'">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6 class="edit-template-item-option-header">Customize Formula</h6>
                                                <textarea class="node-name-input" rows="3" [(ngModel)]="editNode.data.equationOverride" type="text" placeholder="Enter formula if custom formula required"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6 class="edit-template-item-option-header">Calculation Options</h6>
                                                <input
                                                [checked]="editNode.data.isExcludableInOtherCalculations"
                                                (change)="toggleIncludable()"
                                                type="checkbox" />
                                                Add include/exclude option in categorization?
                                            </div>
                                        </div>
                                    </ng-container>
                                    <hr />
                                    <ng-container *ngIf="this.templateType === 'STANDARD' && (editNode.data.className=='AdjustedStandardItem' || editNode.data.className=='NonAdjustableStandardItem' || editNode.data.className=='ManualInputItem')">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="edit-template-item-option-header">Statement Builder Behavior</p>
                                                <ng-container>
                                                    <button
                                                        class="btn roll-option-inactive"
                                                        [ngClass]="{'rollup-option-active': editNode.data.isStatementBuilderBehaviorAdditive==true}"
                                                        (click)="editNode.data.isStatementBuilderBehaviorAdditive = true;"
                                                        title="Use Addends">
                                                        <span>Use Addends</span>
                                                    </button>
                                                    <button class="btn roll-option-inactive"
                                                        [ngClass]="{'rollup-option-active': editNode.data.isStatementBuilderBehaviorAdditive==false}"
                                                        (click)="editNode.data.isStatementBuilderBehaviorAdditive = false;"
                                                        title="Only Period End Value">
                                                        <span>Only Period End Value</span>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <hr />
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <input
                                            [checked]="showItemvalidator"
                                            (change)="toggleHideShowValidator($event);"
                                            type="checkbox"
                                            />
                                            Add Validation Rule?
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="showItemvalidator">
                                        <div class="col-sm-12">
                                            If {{editNode.data.label}} is
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Comparer:{{'&#160;'}}</label> <select [(ngModel)]="editNode.data.validator.comparer">
                                                <option *ngFor="let opt of validatorComparerOptions" [value]="opt.value" [selected]="opt.value == editNode.data.validator.comparer">{{opt.label}}</option>
                                            </select>
                                        </div>

                                        <div class="col-sm-12">
                                            <label>Equation:{{'&#160;'}}</label> <input [(ngModel)]="editNode.data.validator.thresholdEquation" type="text" placeholder="e.g. =1.25" />
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Mark it:{{'&#160;'}}</label> <select [(ngModel)]="editNode.data.validator.mark">
                                                <option *ngFor="let opt of validatorMarker" [value]="opt.value" [selected]="opt.value == editNode.data.validator.mark">{{opt.label}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h6 class="edit-template-item-option-header">Additional Options</h6>
                                            <div *ngIf="!this.editNode.data.isDynamicAdjustmentStatement && ['StandardItem', 'GenericStandardItem'].includes(this.editNode.data.className) && this.templateType === 'STANDARD'">
                                                <button class="btn btn-link additional-options-item" (click)="addCustomTemplateItem()"><i class="fas fa-sitemap"></i> Add custom item as a child</button>
                                            </div>
                                            <div *ngIf="isUcaItem()">
                                              <button class="btn btn-link additional-options-item" (click)="openUcaEquationModal(editNode)"><i class="fas fa-calculator"></i>Build UCA Formula</button>
                                          </div>
                                            <!-- ngIf 'is das section header'-->
                                            <div *ngIf="this.templateType === 'STANDARD' && (editNode.data.dynamicAdjustmentStatementItemType == DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE || editNode.data.children?.length)">
                                                <button class="btn btn-link additional-options-item"
                                                        (click)="addHeaderItemAsChild(editNode, editNode.data.dynamicAdjustmentStatementItemType == DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE)"><i
                                                    class="fas fa-calculator"></i>Add Header Item as Child
                                                </button>
                                            </div>
                                            <!-- ngIf 'is das section header'-->
                                            <div *ngIf="this.templateType === 'STANDARD' && this.editNode.data.dynamicAdjustmentStatementItemType == DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE">
                                                <button class="btn btn-link additional-options-item"
                                                        (click)="addGenericEquation(true)"><i
                                                    class="fas fa-calculator"></i>Add Custom Calculated Item as Child
                                                </button>
                                            </div>
                                            <!-- ngIf 'is das section header'-->
                                            <div *ngIf="this.templateType === 'STANDARD' && this.editNode.data.dynamicAdjustmentStatementItemType == DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE">
                                                <button class="btn btn-link additional-options-item" (click)="addCustomTemplateItem(true)"><i class="fas fa-sitemap"></i>Add Manual User Input Item as Child</button>
                                            </div>
                                            <!-- ngIf 'is non-das item leaf node' -->
                                            <div *ngIf="this.templateType === 'STANDARD' && !this.editNode.data.isDynamicAdjustmentStatement && !editNode.data.children?.length && (editNode.data.className=='StandardItem'|| editNode.data.className=='CalculatedItem')">
                                                <button class="btn btn-link additional-options-item"
                                                        (click)="addItemToDASSection(editNode)"><i
                                                    class="fas fa-calculator"></i>Add Item To DAS Section
                                                </button>
                                            </div>
                                            <div *ngIf="!editNode.data.isStatic || editNode.data.isDynamicAdjustmentStatement">
                                                <button class="btn btn-link additional-options-item"
                                                        (click)="removeItemFromWorkingTree(editNode)"><i
                                                    class="fas fa-trash-alt"></i> Remove from template
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noTemplateItemSelected>
                            <p>Select an item to edit.</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-edit-template-mappings
    *ngIf="editTemplateMappingsActivated"
    [templateId]="templateId"
    [templateName]="spreadingTemplate.name"
    [incomeStatementTaxonomyItems]="retrieveFinancialStatementTaxonomyItems()"
    [balanceSheetTaxonomyItems]="retrieveFinancialStatementTaxonomyItems(false)"
></app-edit-template-mappings>
