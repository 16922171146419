import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SpreadingMapTemplates} from '../models/standard-item-templates';
import {Row} from '../components/main/spreading/models/models';
import {DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES, TAXONOMY_ITEM_IDS} from '@utils/constants';


@Injectable()
export class SpreadingTemplateService {
  constructor(private apiService: ApiService) { }

  getEmptyTemplate(templateType = 'STANDARD'): Observable<any> {
    const payload = {
      template_type: templateType,
    };

    return this.apiService.send('Get', `/api/spreadingtemplates/read_empty`, payload).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  getFincuraTaxonomy(): Observable<any> {
    return this.apiService.send('Get', `/api/spreadingtemplates/read_fincura_taxonomy`).pipe(
      map(data => {
        return data.response.objects;
      })
    );
  }

  createTemplate(data: any): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates`, data).pipe(
      map(data => data.response.objects));
  }

  updateTemplate(templateId: number, data: any): Observable<any> {
    return this.apiService.send('Patch', `/api/spreadingtemplates/${templateId}`, data).pipe(
      map(data => data.response.objects));
  }

  getTemplates(templateType = 'STANDARD', includeOnlyActiveTemplates = false): Observable<any> {
    const payload = {
      filter: {
        template_type_eq: templateType,
      }
    };
    if (includeOnlyActiveTemplates) {
      payload['filter']['active_eq'] = true
    }

    return this.apiService.send('Post', `/api/spreadingtemplates/all`, payload).pipe(
      map(data => data.response.objects));
  }

  getTemplate(templateId: number): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/all`, {
      filter: {
        id_eq: templateId
      }
    }).pipe(
      map(data => data.response.objects[0]));
  }

  getTemplateByUuid(templateUuid: string): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/all`, {
      filter: {
        uuid_eq: templateUuid
      }
    }).pipe(
      map(data => data.response.objects[0]));
  }

  getSpreadingTaxonomyById(spreadingTemplateId: number): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/company_taxonomy`, {
      spreading_template_id: spreadingTemplateId
    }).pipe(map(data => data.response.objects[0]));
  }

  getTemplateTaxonomy(companyId: number): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/company_taxonomy`, {
      company_id: companyId
    }).pipe(
      map(data => {
        const tmpl = new SpreadingMapTemplates();
        tmpl.incomeStatementTmpl = data.response.objects[0].taxonomy[0].children as Array<Row>;
        tmpl.balanceSheetTmpl = data.response.objects[0].taxonomy[1].children as Array<Row>;
        // Grab Cash Flow if its set
        tmpl.cashFlowStatementTmpl = (data.response.objects[0].taxonomy[2].lineItemId === TAXONOMY_ITEM_IDS.CASH_FLOW_STATEMENT) ? data.response.objects[0].taxonomy[2].children as Array<Row> : [];
        return tmpl;
      }));
  }

  generateGenericEquation(genericEquation: any, isDASItem: boolean = false): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_generic_calculated_item`, genericEquation).pipe(
      map(data => {
        let item = data.response.objects[0];
        if (isDASItem){
          item['isDynamicAdjustmentStatement'] = true;
          item['hasBackgroundColor'] = false;
          item['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.CUSTOM_CALCULATION
          console.log('Created Generic Calculated item: ', item)
        } else if (genericEquation.equationIncludesDasRef) {
          item['className'] = 'CrossSectionDASRefCustomCalc'
        }
        return item
      }));
  }

  generateSubItem(standardLineItemId: number, subItem: any): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_sub_item`, {
      standardLineItemId,
      subItem
    }).pipe(
      map(data => {
        return data.response.objects[0];
      }));
  }

  getTaxFormMappingsForSpreadingTemplate(templateId: number): Observable<any> {
    return this.apiService.send('Get', `/api/spreadingtemplates/${templateId}/read_tax_form_mappings`).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  updateTaxFormMappingOverrides(templateId: number, data: any): Observable<any> {
    return this.apiService.send('Post', `/api/spreadingtemplates/${templateId}/update_tax_form_mapping_overrides`, data).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  downloadTaxFormMappingsToExcel(templateId, templateName) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const api_path = this.apiService._getApiUrl() + `/api/spreadingtemplates/${templateId}/download_tax_form_mappings_as_excel_file`
    window.open(api_path, '_blank');
  }

  duplicateSpreadingTemplate(templateId): Observable<any> {
    const apiRequestBody = {
      'templateIdToDuplicate': templateId
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/duplicate_spreading_template`, apiRequestBody).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  getAvailableTaxonomyItemsForSingleEntityAnalysis(): Observable<any> {
    return this.apiService.send('Get', `/api/spreadingtemplates/get_available_items_for_single_entity_analysis`).pipe(
      map(data => {
        return data.response.objects;
      })
    );
  }

  getAvailableTaxonomyItemsForMultiEntityAnalysis(): Observable<any> {
    return this.apiService.send('Get', `/api/spreadingtemplates/get_available_items_for_multi_entity_analysis`).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  generateGenericStandardItem(
    documentType: string,
    label: string,
    standardSpreadingTemplateNameForEntitySubsection: string = '',
    hasRollup = true,
  ): Observable<any> {
    const payload = {
      document_type: documentType,
      label: `${standardSpreadingTemplateNameForEntitySubsection === '' ? '' : ('(' + standardSpreadingTemplateNameForEntitySubsection + ") ")}${label}`,
      has_rollup: hasRollup
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_generic_item`, payload).pipe(
      map(data => {
        return data.response.objects[0];
      }));
  }

  generateDASManualInputItem(label): Observable<any> {
    return this.apiService.send('Post', '/api/spreadingtemplates/generate_manual_input_item', {label}).pipe(
      map(data => {
        let item = data.response.objects[0];
        item['isDynamicAdjustmentStatement'] = true;
        item['hasBackgroundColor'] = false;
        item['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.MANUAL_USER_INPUT
        console.log('Created Manual User Input item: ', item)
        return item
      })
    )
  }

  generatePreSignedUrlForExcelExportReferenceUpload(filename, fileType, spreadingTemplateUuid): Observable<any> {
    const payload = {
      filename: filename,
      fileType: fileType,
      spreadingTemplateUuid: spreadingTemplateUuid
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_excel_export_reference_upload_presigned_url`, payload).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  retrievePreSignedUrlForExcelExportReferenceDownload(filename, s3Key): Observable<any> {
    const payload = {
      filename: filename,
      s3Key: s3Key,
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/retrieve_excel_export_reference_upload_presigned_url`, payload).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  deleteExcelExportReference(spreadingTemplateId): Observable<any> {
    const payload = {
      templateId: spreadingTemplateId,
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/delete_excel_export_reference`, payload).pipe(
      map(data => {
        return data.response.objects[0];
      })
    );
  }

  generateAdjustableRawAddBackDASItem(sourceItemRef, label, textFormat) {
    const equation = `=${sourceItemRef}`
    const payload = {
      label: label.trim(),
      equation: equation,
      textFormat: textFormat,
    }
    // DAS ITEM TYPE: Adjustable Raw Add-Back Item
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_adjusted_standard_item`, payload).pipe(
      map(data => {
        const object = data.response.objects[0];
        object['className']='AdjustedStandardItem'
        object['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.ADJUSTABLE_RAW_ADD_BACK
        console.log('Created Adjustable Raw Add-Back item: ', object)
        return object;
      }));
  }

  generateNonAdjustableRawAddBackDASItem(sourceItemRef, label, textFormat) {
    const equation = `=${sourceItemRef}`
    const payload = {
      label: label.trim(),
      equation: equation,
      textFormat: textFormat,
    }
    // DAS ITEM TYPE: Non-Adjustable Raw Add-Back Item
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_non_adjustable_standard_item`, payload).pipe(
      map(data => {
        const object = data.response.objects[0];
        object['className'] = 'NonAdjustableStandardItem';
        object['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.NON_ADJUSTABLE_RAW_ADD_BACK
        console.log('Created Non-Adjustable Raw Add-Back item: ', object)
        return object;
      }));
  }

  generateAsReportedDASItem(sourceItemRef, label, textFormat) {
    const equation = `=${sourceItemRef}`
    const payload = {
      label: label.trim(),
      equation: equation,
      textFormat: textFormat,
      dasItemType: DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.AS_REPORTED
    }
    return this.generateGenericEquation(payload).pipe(map(item => {
      // DAS ITEM TYPE: As Reported Template Item
      item['isDynamicAdjustmentStatement'] = true;
      item['hasBackgroundColor'] = false;
      item['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.AS_REPORTED
      console.log('Created As-Reported item: ', item)
      return item
    }))
  }

  generateGenericHeaderItemForDASSection(label, document_type): Observable<any> {
    const payload = {
      label: label.trim(),
      equation: "",
      document_type: document_type
    }

    return this.apiService.send('Post', `/api/spreadingtemplates/generate_generic_header_item`, payload).pipe(
      map(data => {
          // DAS ITEM TYPE: Section Header
          const object = data.response.objects[0];
          object['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.CUSTOM_SECTION_HEADER
          object['isStatic'] = false;
          console.log('Created DAS empty header item: ', object);
          return object
        }
      )
    )
  }

  generateDASSection(label): Observable<any>{
    const payload = {
      document_type: "CALCULATION",
      label: label,
      has_rollup: false
    }
    return this.apiService.send('Post', `/api/spreadingtemplates/generate_generic_header_item`, payload).pipe(
      map(data => {
        const object = data.response.objects[0];
        object['dynamicAdjustmentStatementItemType'] = DYNAMIC_ADJUSTMENT_STATEMENT_ITEM_TYPES.DAS_SECTION_ROOT_NODE
        // DAS ITEM TYPE (sort of) Rat Section Parent Node
        console.log('Created DAS Section parent node: ', object)
        return object
      }));
  }
}
