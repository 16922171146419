import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cell } from '@components/main/spreading/models/models';
import { SpreadingState } from '@components/main/spreading/state/manager';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";

@Component({
  selector: 'app-comment-delete-modal',
  templateUrl: './comment-delete-modal.component.html',
  styleUrls: ['./comment-delete-modal.component.scss']
})
export class CommentDeleteModalComponent {
  @Input() popup: NgxPopupComponent;

  @Input() cellWithComment: Cell;

  @Output() deleteCommentEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(
  ) {
  }

  closeModal() {
    this.popup.close();
  }

  deleteComment(cell: Cell) {
    cell.sourceComments = [];
    this.deleteCommentEvent.emit();
    this.closeModal();
    return;
  }
}
