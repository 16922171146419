import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignedUrl } from '../models/signed-url';
import { ResponseTypes } from '../utils/enums';

@Injectable()
export class BankSettingsService {

    constructor(private apiService: ApiService) {
    }

    getPortalSettings(signedUrl: SignedUrl = null): Observable<any> {
        return this.apiService.send('Get', '/api/bank-settings', {}, ResponseTypes.Json, true, signedUrl).pipe(
            map((data) => {
                return data.response.objects[0];
            }));
    }

    getCName(): Observable<any> {
        return this.apiService.send('Get', '/api/bank-settings').pipe(
            map((data) => {
                return data.response.objects[0]['cname'];
            }));
    }

    getProjectionScenarioTypeLabelOverride(): Observable<any> {
      return this.apiService.send('Get', '/api/bank-settings').pipe(
          map((data) => {
              return data.response.objects[0]['projectionScenarioTypeLabelOverride'];
          }));
    }

    getCustomerIdRelatedBankSettings(): Observable<any> {
      return this.apiService.send('Get', '/api/bank-settings').pipe(
          map((data) => {
              const settings = data.response.objects[0];
              return {
                customerIdLabelOverride: settings['customerIdLabelOverride'],
                customerIdsUtilized: settings['customerIdsUtilized'],
              };
          }));
    }

    getAllBankSettings(): Observable<any> {
      return this.apiService.send('Get', '/api/bank-settings').pipe(
          map((data) => {
              return data.response.objects[0];
          }));
    }

  getUserSessionRelatedBankSettings(): Observable<any> {
    return this.apiService.send('Get', '/api/bank-settings').pipe(
      map((data) => {
        const settings = data.response.objects[0];
        console.log({
          userSessionLifetimeSeconds: settings['userSessionLifetimeSeconds'],
          userSessionWarningDurationSeconds: settings['userSessionWarningDurationSeconds'],
        })
        return {
          userSessionLifetimeSeconds: settings['userSessionLifetimeSeconds'],
          userSessionWarningDurationSeconds: settings['userSessionWarningDurationSeconds'],
        };
      }));
  }
}
