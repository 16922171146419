<div class="comment-container">
    <div class="comment-header"> Comment </div>
    <div  class="comment-text"> {{ commentText }} </div>
    <div class="modal-footer">
        <button class="btn btn-light-primary edit-button" (click)="edit()">
            <i class="fa fa-pencil-alt" style="margin-right: 5px"></i>
            Edit
        </button>
        <button class="btn btn-light-danger" (click)="delete()">
            <i class="fa fa-trash" style="margin-right: 5px"></i>
            Delete
        </button>
    </div>
</div>
