import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';
import { ApiService } from './api.service';
import { Observable, AsyncSubject } from 'rxjs';
import { ResponseTypes } from '../utils/enums';
import { SignedUrl } from '../models/signed-url.js';
import { AppConfigService } from './app-config.service';
import { ThirdPartyErrorHandlerService } from './third-party-error-handler.service';
import { UserService } from './user.service';
import { LoggingService, Logger } from './logging.service';
import { LaunchDarklyService } from './launchdarkly.service';
import { MANUAL_REVIEW_HEADER_ORDER } from '@utils/constants';
import { NON_SPREADABLE_COLUMN_PREP_TYPES } from '@utils/constants';
import { StatementOption } from '@models/statement-option';
import {PubnubConfig} from "pubnub";
import {User} from "@models/user";

@Injectable({
  providedIn: 'root'
})

export class EnvironmentService {
  private _loadTriggered = false;
  private _envConfig: any;

  private _loadEnvSubject = new AsyncSubject();
  private logger: Logger;

  private showInterpolationOptions = false;

  constructor(
    private _segmentService: SegmentService,
    private _thirdPartyErrorHandlerService: ThirdPartyErrorHandlerService,
    private _appConfigService: AppConfigService,
    private _userService: UserService,
    private _apiService: ApiService,
    private _loggingService: LoggingService,
    private _launchDarklyService: LaunchDarklyService,
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('EnvironmentService');

    this.showInterpolationOptions = this._launchDarklyService.flags['interpolation'];
    this._launchDarklyService.flagChange.subscribe((flags) => {
      this.showInterpolationOptions = flags['interpolation'];
    });
  }

  loadEnvConfig(
    internalAdmin?: boolean,
    signedUrl: SignedUrl = null,
    skipTokenCheck: boolean = false
  ): Observable<any> {
    if (!this._loadTriggered) {
      this._loadTriggered = true;
      this._appConfigService.envFromHostname.subscribe( (envFromHost) => {

          // Add metadata to our logging library
          try {
            this._loggingService.rootLogger.metadata._environmentdata.envname = envFromHost.envName;
            this.logger.info(`Logging metadata set to contain environment name: "${envFromHost.envName}"`);
          } catch (err) {
            this.logger.error('Error when attempting to set environment metadata in rootLogger', {errorObject: err});
          }

          return this._apiService.send('get', `/api/environment?environment_name=${envFromHost.envName}`, {},
                                        ResponseTypes.Json, true, signedUrl, internalAdmin)
          .subscribe((data) => {
              this._envConfig = (typeof data === 'string') ? JSON.parse(data) : data; // ternary not required permanently, just there to catch a stale client
              // Enable  service at this point
              this._segmentService.load(this._envConfig['segment_key']);

              // enable logging to datadog
              try {
                this._loggingService.rootLogger.initDatadog(this._envConfig['datadog_client_token']);
              } catch (err) {
                this.logger.error('Error when trying to init logging to datadog', {errorObject: err});
              }

              this._thirdPartyErrorHandlerService.load(this._envConfig['sentry_dsn']);
              this._userService.setLoggingServiceTags(); // need to reset tags after instantiating error handler service
              this._loadEnvSubject.next(true);
              this._loadEnvSubject.complete();
            });
        });
    }

    return this._loadEnvSubject;
  }

  getEnvConfig() {
    return this._envConfig;
  }

  getPubNubSettings(user: User): PubnubConfig {
    return {
      userId: `pn_uid_${user.id}`,
      publishKey: this._envConfig['pubnub_publish_key'],
      subscribeKey: this._envConfig['pubnub_subscribe_key']
    }
  }

  getHandsOnTableLicenseKey(): string {
    return this._envConfig['handsontable_license_key'];
  }

  getOpenApiSchemaUrl(): string {
    return this._envConfig['openapi_schema_url'];
  }

  getCurrencyFormat() {
    return '$#,#.#0';
  }

  getLaunchDarklyClientId() {
    return this._envConfig['launchdarkly_client_id'];
  }

  getStatementOptions(): StatementOption[] {
    let statementOptions = this._envConfig['statement_options'];

    const filteredOutReportingIntervals = ['T3M', 'T6M', 'T9M'];
    const filteredOutHeaderLabels = ['FISCAL YEAR ENDING'];

    if (!this.showInterpolationOptions) {
      filteredOutReportingIntervals.push('TTM');
    }

    statementOptions = statementOptions.filter( statementOption => {
      return !filteredOutHeaderLabels.includes(statementOption.label);
    })

    statementOptions[MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX].values =
      statementOptions[MANUAL_REVIEW_HEADER_ORDER.REPORTING_INTERVAL_INDEX].values.filter( element => {
        return !filteredOutReportingIntervals.includes(element);
      });
    statementOptions[MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX].values =
      statementOptions[MANUAL_REVIEW_HEADER_ORDER.PREPARATION_TYPE_INDEX].values.filter( element => {
        return !NON_SPREADABLE_COLUMN_PREP_TYPES.includes(element);
      });

    return statementOptions;
  }
}
